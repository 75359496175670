.alertSuccess {
  color: #001e38 !important;
}

.ant-notification-notice-message {
  margin-bottom: 10px !important;
}
.ant-notification-notice {
  padding: 16px !important;
}
.ant-notification-notice-description {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  /* margin-left:0px  !important; */
}
@media (max-width: 768px) {
  body > div:first-child {
    height: 100%;
  }
}

.chat-layout {
  height: 100%;
}
